import React from "react"
import Layout from "../components/layout"
import ScoringMainImg from "../images/scoring/scoring-img.png"
import Eye from "../images/scoring/eye.png"
import Accuracy from "../images/scoring/accuracy.png"
import Check from "../images/scoring/check.png"
import Screen from "../images/scoring/screen.png"
import Work from "../images/scoring/work.png"
import {Link} from "gatsby";
import {yandexMetric} from "../components/Functions/yandexMetric";
import SEO from "../components/seo";

export default function Scoring(props) {

        return (
            <Layout location={props.location}>
                <SEO title="Скоринговая система предсказаний"/>
                <section className="scoring">
                    <div className="container">
                        <div className="scoring-box">
                            <div className="scoring-info">
                                <h2 className="scoring-info__title">Черный Список</h2>
                                <p className="scoring-info__sub">
                                    Скоринговая система предсказаний
                                </p>
                                <p className="scoring-info__text">
                                    Настройте коммуникацию с покупателем в зависимости от его
                                    скора и повысьте выкуп. Не отправлять посылки тем, кто их не
                                    выкупит.
                                </p>
                                <Link
                                    className="btn-overall scoring-info__btn"
                                    to="/register/"
                                    onClick={() => yandexMetric("scoringStartUsing")}
                                >
                                    Начать использовать
                                </Link>
                            </div>
                            <div className="scoring-picture">
                                <img className="scoring-img" src={ScoringMainImg} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="predict">
                    <div className="container">
                        <div className="predict-box">
                            <div className="predict-wrap">
                                <div className="predict__icon">
                                    <img className="predict__img" src={Eye} alt="" />
                                </div>
                                <h3 className="predict__title">Что предсказываем?</h3>
                                <p className="predict__paragraph">
                                    Мы предсказываем, с какой вероятностью посылка Почты России
                                    будет выкуплена. Для предсказания нам достаточно знать ФИО,
                                    телефон и почтовый индекс клиента.
                                </p>
                            </div>
                            <div className="predict-wrap">
                                <div className="predict__icon">
                                    <img className="predict__img" src={Accuracy} alt="" />
                                </div>
                                <h3 className="predict__title">Точность</h3>
                                <p className="predict__paragraph">
                                    При прогнозе мы ошибаемся максимум на 5%. Т.е. если мы
                                    говорим, что посылка будет выкуплена с вероятностью 35%, то
                                    реально она будет выкуплена в 30-40% случаев.{" "}
                                </p>
                            </div>
                            <div className="predict-wrap">
                                <div className="predict__icon">
                                    <img className="predict__img" src={Check} alt="" />
                                </div>
                                <h3 className="predict__title">Как нас проверить?</h3>
                                <p className="predict__paragraph">
                                    Все посылки при загрузке в Стрекозу получают скор (вероятность
                                    выкупа). Вы можете дождаться, пока эти посылки получат
                                    финальный статус (возврат или выкуп), и проверить наши
                                    прогнозы.
                                </p>
                            </div>
                            <div className="predict-wrap">
                                <div className="predict__icon">
                                    <img className="predict__img" src={Screen} alt="" />
                                </div>
                                <h3 className="predict__title">Как использовать?</h3>
                                <p className="predict__paragraph">
                                    Настраивайте для “плохих” клиентов (с низкой вероятностью
                                    выкупа) одни тексты, каналы связи и частотность уведомления,
                                    для “хороших” - другие. Это позволяет, с одной стороны,
                                    повышать выкуп, с другой - экономить на уведомлениях. Не
                                    отправляйте с наложенным платежом тем, кто не выкупит посылку.{" "}
                                </p>
                            </div>
                            <div className="predict-wrap">
                                <div className="predict__icon">
                                    <img className="predict__img" src={Work} alt="" />
                                </div>
                                <h3 className="predict__title">Как мы это делаем? </h3>
                                <p className="predict__paragraph">
                                    Мы собрали большую базу данных о клиентах интернет-магазинов.
                                    Эти данные мы анализируем с помощью своего ПО со встроенными алгоритмами машинного обучения и делаем прогноз.
                                    Технологии, которые мы используем: PostgreSQL, ClickHouse - хранение информации; Машинное обучение на основе Python + Catboost.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bid">
                    <div className="container">
                        <div className="bid-box">
                            <div className="bid-box__imposed">
                                <h2 className="bid-box__heading">Доступен экспресс-тест исторически данных.</h2>
                                <Link
                                    className="btn-overall bid-box__btn"
                                    to="/register/"
                                    onClick={() => yandexMetric("scoringLeaveApplication")}
                                >
                                    Оставить заявку
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

            </Layout>
        )

}


